/* ///////////////////////// */
/* Tiny works button animation */
/* ///////////////////////// */

// import icons from "../../../resources/img/chevron-expand-outline.svg";

const expandBtn = document.querySelector(".expand-btn");
const downArrow = document.querySelector(".expand-icon-down");
const otherProjectsTiles = document.querySelector(".other-projects--grid");

expandBtn.addEventListener("click", function () {
  otherProjectsTiles.classList.toggle("hidden");

  downArrow.classList.toggle("rotate");
});
